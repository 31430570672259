import Vue from "vue";

import {
  COMMIT_ROLE,
  FETCH_AGENT,
  IS_CONNECTED,
  IS_FIRST_LOGIN,
  MUTE_AGENT,
  NEW_STATE,
  SAVE_CURRENT_USER,
  SEND_DIGITS,
  SET_AGENT,
  SET_CONNECTED,
  SET_CURRENT_USER,
  SET_ROLE,
  SET_STATE,
  UNMUTE_AGENT,
  DOES_ROUTING_PROFILE_HAVE_QUEUES,
} from "./actions.type";
import { get, findIndex } from "lodash";

import CcpService from "@/common/services/ccp.service";
import { PeerCallStatus } from "@/common/constant";
import { defaultConnectStatuses } from "@connectpath/common";
import { AGENT_STATUSES } from "@connectpath/common";
import { i18n } from "@/plugins/language";

const systemStates = ["Busy", "PendingBusy", "Connected"];

function assertAllowedStates(states) {
  return states.filter((state) => !systemStates.includes(state.name));
}

const initialState = {
  agent: {
    configuration: {
      agentStates: [
        {
          name: "",
        },
      ],
      role: "",
      routingProfile: {
        queues: [],
      },
    },
    status: {
      type: "test",
    },
    AWSAccountID: false,
  },

  quickConnects: [],
  queuetags: [],
  isFirstLogin: false,
  user: {},
  agentActivity: [],
  isConnected: false,
  recentDeskphone: [],
  newState: {},
};

export const state = Object.assign({}, initialState);

export const actions = {
  [IS_CONNECTED](context, connected) {
    return context.commit(SET_CONNECTED, connected);
  },
  async [FETCH_AGENT](context, agent) {
    try {
      const response = await CcpService.getAgent(agent);
      context.commit(SET_AGENT, response);
      return response;
    } catch (error) {
      console.error("Error fetching agent: ", error);
      throw error;
    }
  },
  async [DOES_ROUTING_PROFILE_HAVE_QUEUES](context, agent) {
    if (await CcpService.doesRoutingProfileHasQueues(agent)) return;
    const agentRoutingProfileName = await CcpService.getAgentRoutingProfileName(agent);
    Vue.prototype.$Notice.error({
      title: i18n.t("common.error"),
      desc: i18n.t("agentCcp.errorRoutingProfileDoensthaveAnyQueue", [agentRoutingProfileName]),
      duration: 0,
    });
  },
  [SET_CURRENT_USER](context, user) {
    return context.commit(SAVE_CURRENT_USER, user);
  },
  async [NEW_STATE](context, newState) {
    try {
      const updatedState = await CcpService.setState(newState);
      context.commit(SET_STATE, newState);
      return updatedState;
    } catch (error) {
      console.error("Error changing state: ", error);
    }
  },
  [SET_ROLE](context, role) {
    context.commit(SET_ROLE, role);
  },
  [MUTE_AGENT]() {
    return CcpService.muteAgent();
  },
  [UNMUTE_AGENT]() {
    return CcpService.unMuteAgent();
  },
  async [SEND_DIGITS](context, digits) {
    try {
      await CcpService.sendDigits(digits);
    } catch (error) {
      console.error(error);
    }
  },
  updateQueuetagIndex(context, selectedQueue) {
    let index = findIndex(context.state.queuetags, (i) => {
      return i.queueId == selectedQueue;
    });

    let tag = context.state.queuetags[index];
    context.commit("removeQueuetagAt", index);

    const timeout = setTimeout(() => {
      context.commit("pushQueuetagAtFront", tag);
      clearTimeout(timeout);
    }, 600);
  },
  getAgentRecentDeskphone({ state, commit }) {
    try {
      let d = JSON.parse(localStorage.getItem(state.agent.AWSAgentID));

      commit("initializeAgentRecentDeskphone", d);
    } catch (e) {
      console.error("syncAgentRecentDeskphone", e);
    }
  },
  updateAgentRecentDeskphone({ state }) {
    try {
      localStorage.setItem(state.agent.AWSAgentID, JSON.stringify(state.recentDeskphone));
    } catch (error) {
      console.error(error);
    }
  },
  updateAgentConfiguration({ commit }, payload) {
    commit("updateConfigurationLocal", payload);
  },
  updateAgentAvailabilityState({ commit }, payload) {
    commit("setAvailabilityState", payload);
  },
  //TODO: delete this action and move service call to component itself
  async fetchQuickConnects({ commit }, { agent }) {
    const endpoints = await CcpService.getEndpoints(agent);
    commit("setQuickConnects", endpoints);
  },
};

export const mutations = {
  [SET_CONNECTED](state, connected) {
    state.isConnected = connected;
  },
  [IS_FIRST_LOGIN](state, firstLogin) {
    state.isFirstLogin = firstLogin;
  },
  [SAVE_CURRENT_USER](state, user) {
    state.api.getUser = { ...state.api.getUser, ...user };
  },
  [COMMIT_ROLE](state, role) {
    state.agent.configuration.role = role;
  },

  [SET_AGENT](state, agent) {
    agent;
    let data = agent;

    if (
      this.state?.currentUser?.profile?.Contacts &&
      this.state?.currentUser?.profile?.Contacts[0]?.State === "CONNECTED_ONHOLD"
    ) {
      data.summary.StatusName = "Hold";
      data.summary.StatusStartTimestamp = this.state?.currentUser?.profile?.Contacts[0]?.StateStartTimestamp;
    }

    state.agent = data;

    let globalQueue = {
      name: "Global View",
      queueId: "global",
    };

    if (
      this.state.currentUser &&
      this.state.currentUser.securityProfile.Realm !== "instance" &&
      !(this.state.currentUser.profile.Username || "").includes("IAM@")
    ) {
      state.queuetags = [
        globalQueue,
        ...data.configuration.routingProfile.queues
          .filter((queue) => {
            return queue.name;
          })
          .sort((a, b) => {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }),
      ];
    }
  },

  [SET_STATE](state, newState) {
    state.newState = newState;
  },
  setQueueTags(state, queues) {
    state.queuetags = [
      {
        name: "Global View",
        queueId: "global",
      },
      ...queues
        .map((q) => {
          return {
            name: q.Name,
            queueARN: q.Arn,
            queueId: q.id,
          };
        })
        .sort((a, b) => {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    ];
  },
  updateAgentStatus(state, payload) {
    state.agent.summary = payload;
  },
  removeQueuetagAt(state, index) {
    state.queuetags.splice(index, 1);
  },
  pushQueuetagAtFront(state, tag) {
    state.queuetags.unshift(tag);
  },
  setAgentSoftPhoneEnabled(state, payload) {
    state.agent.configuration.softphoneEnabled = payload;
  },
  setAgentConfigExtension(state, payload) {
    state.agent.configuration.extension = payload;
  },
  setAvailabilityState(state, payload) {
    state.agent.availabilityState = payload;
  },
  initializeAgentRecentDeskphone(state, payload) {
    if (payload !== null) {
      payload.forEach((p) => {
        state.recentDeskphone.push(p);
      });
    }
  },
  addAgentRecentDeskphone(state, payload) {
    state.recentDeskphone.push(payload);
  },
  initAgent(state) {
    state.agent = initialState.agent;

    state.queuetags = [];
    state.isFirstLogin = false;
    state.user = {};
    state.agentActivity = [];
    state.isConnected = false;
    state.recentDeskphone = [];
  },
  updateAgentState(state, value) {
    state.agent = value.agent;

    state.queuetags = value.queuetags;
    state.isFirstLogin = value.isFirstLogin;
    state.user = value.user;
    state.agentActivity = value.agentActivity;
    state.isConnected = value.isConnected;
    state.recentDeskphone = value.recentDeskphone;
  },

  updateConfigurationLocal(state, payload) {
    state.agent.configuration = payload;
  },
  setQuickConnects(state, quickConnectEndpoints) {
    state.quickConnects = quickConnectEndpoints;
  },
};

export const getters = {
  agent: (state) => state.agent,
  AWSAgentID: (state) => state.agent.AWSAgentID,
  agentStates: (state) => assertAllowedStates(state.agent.configuration.agentStates),
  agentStatesNamesList: (state) => assertAllowedStates(state.agent.configuration.agentStates).map((state) => state.name),
  agentCustomStatuses: (state) => {
    return assertAllowedStates(state.agent.configuration.agentStates).filter(
      (status) => !defaultConnectStatuses.includes(status.name)
    );
  },
  quickConnects: (state) => [...state.quickConnects],
  agentStatus: (state) => state.agent.state,
  availabilityState: (state) => state.agent.availabilityState,
  agentUsername: (state) => (state.agent.configuration ? state.agent.configuration.username : null),
  agentFirstName: (state) => (state.agent.configuration ? state.agent.configuration.name : null),
  isConnected: (state) => state.isConnected,
  isFirstLogin: (state) => state.isFirstLogin,
  isAuthenticated: (state) => state.isConnected,
  agentStatusDuration: (state) => state.agent.stateDuration,
  agentActivity: (state) => state.agentActivity,
  AWSAccountID: (state) => state.agent.AWSAccountID,
  agentSummary: (state) => state.agent.summary,
  getInstanceId: (state) => state.agent.summary.InstanceId,
  queueTags: (state) => state.queuetags,
  onCall(state, getters, rootState, rootGetters) {
    let states = ["CallingCustomer", "PendingBusy", "Busy", PeerCallStatus.Connecting, PeerCallStatus.Connected];
    let agentStatus = getters.agentStatus;
    return (
      (states.includes(get(agentStatus, "name")) && rootGetters.api.getUser.Contact) ||
      states.includes(rootState.team.callStatus)
    );
  },
  recentDeskphone: (state) => {
    return state.recentDeskphone;
  },
  customAgentStates: (state) => {
    let agentStates = assertAllowedStates(state.agent.configuration.agentStates);
    return agentStates.filter((state) => state.type === AGENT_STATUSES.NOT_ROUTABLE);
  },
  getMaximumOfTasks: (state) => {
    return state.agent.data?.configuration.routingProfile.channelConcurrencyMap;
  },
  getNewState: (state) => {
    return state.newState;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
